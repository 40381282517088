import React, { Component } from 'react'
import { YMaps, Map, Placemark } from "react-yandex-maps";
import classes from './Contacts.css'

export class Contacts extends Component {
    render() {
        var mapData = {
            center: [57.800243, 40.956017],
            zoom: 16
        };

        var coordinates = [
            [57.800243, 40.956017]
        ];

        return (
            <div className={classes.Contacts}>
                <div className={classes.TitleConteiner}>
                    <div className={classes.Title}>Контакты</div>
                </div>
                <div className={classes.ContactMap}>
                    <div className={classes.ContactContainer}>
                        <h1>
                            Адрес офиса ОАО "Ладья"​:
                        </h1>
                        <span>
                            156007, г. Кострома, улица Ленина, д. 160, лит. Д, оф. 1
                        </span>
                        <h1>
                            Контактные телефоны:
                        </h1>
                        <span>
                            <span className={classes.RightPadding}>(4942) 54-20-88</span>
                            <span>+79303202088</span>
                        </span>
                    </div>
                    <YMaps>
                        <div className={classes.Map}>
                            <Map
                                width={800}
                                height={400}
                                defaultState={mapData}
                            >
                                {coordinates.map(coordinate => <Placemark geometry={coordinate} />)}
                            </Map>
                        </div>
                    </YMaps>
                </div>
            </div>
        )
    }
}

export default Contacts 