import React, { useState } from 'react'
import { storage } from '../../firebase'
import classes from './DocumentUpload.css'

const DocumentUpload = () => {
    const [document, setDocument] = useState(null)

    const handleChange = e => {
        if (e.target.files[0]) {
            setDocument(e.target.files[0])
        }
    }

    const handleUpload = () => {
        if (document) {
            const uploadTask = storage.ref(`documents/${document.name}`).put(document)
            
            uploadTask.on(
                "state_changed",
                snapshot => {},
                error => {
                    console.log(error)
                },
                () => {
                    storage
                        .ref("documents")
                        .child(document.name)
                        .getDownloadURL()
                        .then(url => {
                            console.log(url)
                        })
                }
    
            )
        }
    }

    return (
        <div className={classes.DocumentUpload}>
            <input className={classes.Input} type="file" onChange={handleChange} />
            <button className={classes.Button} onClick={handleUpload}>Загрузить</button>
        </div>
    )
}

export default DocumentUpload
