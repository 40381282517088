import React, { Component } from 'react'
import classes from './About.css'

export class About extends Component {
    render() {
        return (
            <div className={classes.About}>
                <div className={classes.TitleConteiner}>
                    <div className={classes.Title}>О компании</div>
                </div>
                <div className={classes.Content}>
                    <h1>История создания и переименования</h1>
                    <div className={classes.TextBlock}>
                        <p>Полное фирменное название: <b>акционерное общество открытого типа "Чековый инвестиционный фонд "Ладья"</b></p>
                        <p>Дата введения наименования: <b>28.01.1993 г.</b></p>
                        <p>Основание введения наименования: <b>Постановление Главы администрации г. Костормы от 28.01.1993 г. № 266</b></p>
                        <p>Идентификационный номер налогоплатильщика: <b>4401007113</b></p>
                    </div>
                    <div className={classes.TextBlock}>
                        <p>Полное фирменное название: <b>открытое акционерное общество "Ладья"</b></p>
                        <p>Сокращенное фирменное наименование: <b>ОАО "Ладья"</b></p>
                        <p>Дата введения наименования: <b>24.05.1996 г.</b></p>
                        <p>Основание введения наименования: <b>Приказ № 68 Регистрационной палаты администрации г. Костромы на основании решения годового общего собрания акционеров АООТ ЧИФ "Ладья" от 13.05.1996г</b></p>
                    </div>
                    <div className={classes.TextBlock}>
                        <p>Полное фирменное название: <b>открытое акционерное общество "Ладья"</b></p>
                        <p>Сокращенное фирменное наименование: <b>ПАО "Ладья"</b></p>
                        <p>Дата введения наименования: <b>11.07.2017 г.</b></p>
                    </div>
                </div>
            </div>
        )
    }
}

export default About
