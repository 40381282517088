import React, { Component } from 'react'
import classes from './Navigation.css'
import { NavLink } from 'react-router-dom'

export class Navigation extends Component {

    render() {

        const isAuth = !!localStorage.token

        const docUpload = (
            <div className={classes.Item}>
                <NavLink to='/documentupload' exact={false}>
                    Загрузить документ
                </NavLink>
            </div>
        )
        const newPost = (
            <div className={classes.Item}>
                <NavLink to='/createpost' exact={false}>
                    Создать новость
                </NavLink>
            </div>
        )

        return (
            <div className={classes.Navigation}>
                <div className={classes.Content}>
                    <div className={classes.NavList}>
                        <img className={classes.Logo} src={require('../../img/logo.png')} alt="LogoText" />
                        <div className={classes.Item}>
                            <NavLink to='/' exact={true}>
                                О компании
                            </NavLink>
                        </div>
                        <div className={classes.Item}>
                            <NavLink to='/documents' exact={false}>
                                Документы
                            </NavLink>
                        </div>
                        <div className={classes.Item}>
                            <NavLink to='/news' exact={false}>
                                Новости
                            </NavLink>
                        </div>
                        <div className={classes.Item}>
                            <NavLink to='/contacts' exact={false}>
                                Контакты
                            </NavLink>
                        </div>
                        {isAuth ? docUpload : <div></div>}
                        {isAuth ? newPost : <div></div>}
                    </div>
                    <div className={classes.PhoneMail}>
                        <div className={classes.Phone}>
                            (4942) 54-20-88, +79303202088
                        </div>
                        <div className={classes.Mail}>
                            pao@ladia44.ru
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Navigation
