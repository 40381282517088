import React, { Component } from 'react';
import Layout from './hoc/Layout/Layout'
import Header from './components/Header/Header'
import { Route, Switch, Redirect } from 'react-router-dom'
import News from './containers/News/News'
import About from './containers/About/About'
import Contacts from './containers/Contacts/Contacts'
import Documets from './containers/Documets/Documents'
import DocumentUpload from './containers/DocumentUpload/DocumentUpload'
import CreatePost from './containers/CreatePost/CreatePost'
import Auth from './containers/Auth/Auth'

class App extends Component {
  render() {

    const isAuth = !!localStorage.token

    let routes = (
      <Switch>
        <Route path='/documents' component={Documets} />
        <Route path='/news' component={News} />
        <Route path='/contacts' component={Contacts} />
        <Route path='/auth' component={Auth} />
        <Route path='/' component={About} />
        <Redirect to='/' />
      </Switch>
    )

    if (isAuth) {
      routes = (
        <Switch>
          <Route path='/documents' component={Documets} />
          <Route path='/news' component={News} />
          <Route path='/contacts' component={Contacts} />
          <Route path='/documentupload' component={DocumentUpload} />
          <Route path='/createpost' component={CreatePost} />
          <Route path='/' component={About} />
          <Redirect to='/' />
        </Switch>
      )
    }

    return (
      <div>
        <Header />
        <Layout>
          {routes}
        </Layout>
      </div>
    );
  }
}

export default App;
