import firebase from 'firebase/app'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyA8rZhaBiBb7lYuAoWDT352XN3Xk8BHjnI",
    authDomain: "ladia-691ac.firebaseapp.com",
    databaseURL: "https://ladia-691ac.firebaseio.com",
    projectId: "ladia-691ac",
    storageBucket: "ladia-691ac.appspot.com",
    messagingSenderId: "1058789192088",
    appId: "1:1058789192088:web:b4791c1ed2bcce78b6fbed",
    measurementId: "G-MJJMTPTB9J"
};

firebase.initializeApp(firebaseConfig)

const storage = firebase.storage()

export { storage, firebase as default } 