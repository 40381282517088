import React, { Component } from 'react'
import classes from './Documents.css'
import { storage } from '../../firebase'

export class Documents extends Component {
    state = {
        documents: []
    }

    renderDocs() {
        return (this.state.documents).map((item, index) => {
            return (
                <div className={classes.DocItem} key={index}>
                    <a className={classes.Doc} href={item.url} rel="noopener noreferrer" target="_blank" download>
                        <img className={classes.DocImg} src={require('../../img/doc.png')} alt="DocImg" />
                        <span className={classes.DocName}>{item.name}</span>
                    </a>
                </div>
            )
        })
    }

    componentWillMount() {
        const documents = storage.ref().child('documents')
        const documentsArray = []

        documents.listAll().then(res => {
            res.items.forEach(itemRef => {
                itemRef.getDownloadURL().then(url => {
                    documentsArray.push({ name: itemRef.name, url: url })
                    this.setState({
                        documents: documentsArray
                    })
                })
            });
        }).catch(function (error) {
            console.log(error)
        });
    }

    render() {
        return (
            <div className={classes.Documents}>
                <div className={classes.TitleConteiner}>
                    <div className={classes.Title}>Документы</div>
                </div>
                <div className={classes.Content}>
                    {
                        this.state.documents.length ? this.renderDocs() : <div></div>
                    }
                </div>
            </div>
        )
    }
}

export default Documents
