import React, { Component } from 'react'
import Navigation from '../Navigation/Navigation'
import classes from './Header.css'

export class Header extends Component {
    render() {
        return (
            <div className={classes.Header}>
                <Navigation />
                <div className={classes.HeaderImg}>
                    <div className={classes.LogoContainer}>
                        <img className={classes.Logo} src={require('../../img/logo.png')} alt="Logo" />
                        <img className={classes.LogoText} src={require('../../img/logoText.png')} alt="LogoText" />
                    </div>
                </div>
            </div>
        )
    }
}

export default Header
