import React, { Component } from 'react'
import classes from './News.css'
import Post from '../../components/Post/Post'
import axios from 'axios'

export class News extends Component {
    state = {
        posts: []
    }

    handleDelete = async (id) => {
        axios.delete(`https://ladia-691ac.firebaseio.com/news/${id}.json`)
        .then((response) => {
            window.location.reload()
        }, (error) => {
            console.log(error)
        })
    }

    renderPosts() {
        const isAuth = !!localStorage.token

        return this.state.posts.map(item => {
            return (
                <div className={classes.Post} key={item.id}>
                    <Post 
                        title={item.title}
                        text={item.text}
                        date={item.date}
                    />
                    {
                        isAuth ? <button className={classes.Button} onClick={this.handleDelete.bind(this, item.id)}>Удалить</button> : <div></div>
                    }
                </div>
            )
        })
    }

    async componentDidMount() {
        try {
            const response = await axios.get('https://ladia-691ac.firebaseio.com/news.json')
            const posts = [];
            Object.keys(response.data).forEach((key) => {
                posts.push({
                    id: key,
                    title: response.data[key].title,
                    text: response.data[key].text,
                    date: response.data[key].date
                })
            })

            this.setState({
                posts: posts
            })
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        return (
            <div className={classes.News}>
                <div className={classes.TitleConteiner}>
                    <div className={classes.Title}>Новости</div>
                </div>
                <div className={classes.Content}>
                    {
                        this.state.posts.length ? this.renderPosts() : <div></div>
                    }
                </div>
            </div>
        )
    }
}

export default News
