import React, { Component } from 'react'
import classes from './CreatePost.css'
import axios from 'axios'

export class CreatePost extends Component {
    state = {
        post: {
            title: '',
            text: '',
            date: ''
        }
    }

    onChangeInput = (event) => {
        const post = { ...this.state.post };

        post.title = event.target.value;
        this.setState({
            post
        })
    }

    onChangeText = (event) => {
        const post = { ...this.state.post };
        
        post.text = event.target.value;
        this.setState({
            post
        })
    }

    savePost = async event => {
        const post = { ...this.state.post };
        
        post.date = new Date().toLocaleDateString('ru-RU');
        
        try {
            await axios.post('https://ladia-691ac.firebaseio.com/news.json', post)
        } catch (erroe) {
            console.log(erroe)
        }
    }

    render() {
        return (
            <div className={classes.CreatePostContainer}>
                <div className={classes.CreatePost}>
                    <div className={classes.InputContainer}>
                        <label className={classes.InputLabel} htmlFor="name">Шапка новости</label>
                        <input onChange={this.onChangeInput} className={classes.Input} placeholder="Текст шапки..." type="text" name="name" required></input>
                    </div>
                    <textarea onChange={this.onChangeText} className={classes.Text} name="text" id="" cols="30" rows="10" maxLength="2000" placeholder="Текст новости..."></textarea>
                    <button className={classes.Button} onClick={this.savePost}>Загрузить</button>
                </div>
            </div>
        )
    }
}

export default CreatePost
