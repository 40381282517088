import React from 'react'
import classes from './Post.css'

const Post = props => {
    return (
        <div className={classes.Post}>
            <div className={classes.Head}>
                <div className={classes.Title}>{props.title}</div>
                <div className={classes.Date}>{props.date}</div>
            </div>
            <div className={classes.Text}>{props.text}</div>
        </div>
    )
}

export default Post
